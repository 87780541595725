import { Injectable } from '@angular/core';
import { convertToInt, convertUrlParamsToObject } from '../../helper-functions';
import { ConfirmUserModel, UrlParameterFor } from './url.model';

@Injectable({
  providedIn: 'platform',
})
export class UrlService {
  private _paramData: any;
  public get paramData(): any {
    return this._paramData;
  }
  public set paramData(v: any) {
    this._paramData = v;
  }

  private _urlState: UrlParameterFor;
  public get urlState(): UrlParameterFor {
    return this._urlState;
  }
  public set urlState(v: UrlParameterFor) {
    this._urlState = v;
  }

  constructor() {
    this.resetParams();
  }

  public getAllQueryParams(): void {
    try {
      let queryArr = window.location.href.split('?');
      let paramData = convertUrlParamsToObject(queryArr[1]) ?? 0;

      if (paramData) {
        if (paramData?.type?.length) {
          if (paramData?.code?.length && paramData?.username?.length) {
            // this.router.navigateByUrl('/create-account');
            this._paramData = new ConfirmUserModel({
              code: paramData.code,
              username: paramData.username,
              type: convertToInt(paramData.type),
            });

            if (this._paramData.type === 1) {
              this._urlState = UrlParameterFor.ResetPassword;
              return;
            }

            if (this._paramData.type === 2) {
              this._urlState = UrlParameterFor.ConfirmAccount;
              return;
            }
          }
        }
      }

      this._urlState = UrlParameterFor.None;
    } catch (error) {
      console.error(error);
    }
  }

  public resetParams(): void {
    this._urlState = UrlParameterFor.None;
    this._paramData = null;
  }
}
