import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/auth/auth.service';
import { FSMiniLoaderService } from './shared/modules/fs-mini-loader/fs-mini-loader.service';
import { GlobalService } from './shared/services/global.service';
import { convertUrlParamsToObject, sleep } from './shared/helper-functions';
import { AuthState } from './shared/enums/auth-state.enum';
import { ChangePasswordObject } from './shared/models/player.model';
import { FsToastService } from './shared/modules/fs-popups/fs-toast/fs-toast.service';
import { PageFrameService } from './shared/modules/fs-page-frame/fs-page-frame.service';
import { LoggerService } from './shared/services/logger.service';
import { RequestToJoinTeamParamModel } from './shared/services/url/url.model';
import { UrlService } from './shared/services/url/url.service';
import {
  ConfirmUserModel,
  UrlParameterFor,
} from './shared/services/url/url.model';
import { AWSResponseType } from './pages/auth/auth.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  notifId: number;
  authState = AuthState;

  constructor(
    public authService: AuthService,
    public loaderService: FSMiniLoaderService,
    private globalService: GlobalService,
    private toastService: FsToastService,
    public pageFrameService: PageFrameService,
    private loggerService: LoggerService,
    private urlService: UrlService,
    public router: Router
  ) {
    this.notifId = 0;
    this.loggerService.printVersion();

    let queryArr = window.location.href.split('?');
    let queryObj = convertUrlParamsToObject(queryArr[1]) ?? 0;

    if (queryObj?.code?.length && queryObj?.username?.length) {
      this.authService.changePasswordUrlObject = new ChangePasswordObject({
        ...queryObj,
      });
    } else if (queryObj?.n) {
      this.notifId = +queryObj.n;

      if (!Number.isNaN(this.notifId) && this.notifId) {
        if (queryObj?.p && queryObj?.t) {
          window.localStorage.setItem(
            '_fs_request_to_join_team',
            JSON.stringify(
              new RequestToJoinTeamParamModel({
                notificationId: this.notifId,
                playerId: queryObj?.p,
                teamId: queryObj?.t,
              })
            )
          );
        } else {
          window.localStorage.setItem(
            '_fs_join_team_notif_id',
            this.notifId.toString()
          );
        }

        // if (this.authService.isAuthenticate === AuthState.False) {

        //   this.router.navigateByUrl('login');
        //   return;
        // } else {
        //   this.router.navigate(['teams', 'join'], {
        //     queryParams: {
        //       n: this.notifId,
        //     },
        //   });
        // }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    await this.checkUserAuth();
    await this.checkConfirmationAccount();
  }

  async checkUserAuth(): Promise<void> {
    try {
      this.loaderService.loader = true;
      this.authService.isAuthenticate = AuthState.False;

      const result = await this.authService.isTokenValid();
      if (!result) {
        await this.authService.unautorized([
          '_fs_join_team_notif_id',
          '_fs_request_to_join_team',
        ]);
        return;
      }

      const isAuthenticate =
        await this.authService.isAuthenticatedCognitoGroups();
      //console.log('isAuthenticate : ', isAuthenticate);

      if (!isAuthenticate) {
        await this.authService.unautorized();
        this.toastService.show('ERROR', 'User not compete player');
        return;
      }

      const player = await this.authService.getUserData();
      if (!player) {
        await this.authService.unautorized([
          '_fs_join_team_notif_id',
          '_fs_request_to_join_team',
        ]);
        return;
      }

      this.globalService.player = player;

      await sleep(100);

      this.authService.isAuthenticate = AuthState.True;
    } catch (error) {
      console.error(error);
      await this.authService.unautorized([
        '_fs_join_team_notif_id',
        '_fs_request_to_join_team',
      ]);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async checkConfirmationAccount(): Promise<void> {
    if (this.urlService.urlState === UrlParameterFor.ConfirmAccount) {
      this.loaderService.loader = true;

      const isConfirmed = await this.authService.confirmSignUp(
        new ConfirmUserModel({
          ...this.urlService.paramData,
        })
      );

      if (isConfirmed.type === AWSResponseType.Success) {
        this.router.navigateByUrl('/login').then((_) => {
          this.urlService.resetParams();
          this.toastService.show('SUCCESS', 'Your account has been verified.');
        });
      }

      this.loaderService.loader = false;
    }
  }
}

//Comment for test checkin on github
