import { FSChips } from '../modules/fs-controls/fs-chips/fs-chips.model';
import { AddFavoriteVenue } from '../static-objects';

export class NavigationBarItem {
  id?: number;
  name?: string;
  view?: string;
  route?: string;
  urlParam?: string;
  icon?: string;
  expandable?: boolean;
  activate?: boolean;
  children?: NavigationBarItem[];

  constructor(model: NavigationBarItem) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.view = model.view || '';
    this.route = model.route || '';
    this.icon = model.icon || '';
    this.expandable = model.expandable || false;
    this.activate = model.activate || false;
    this.children = model.children || (null as any);
  }
}

export class VenueCard {
  id?: number;
  label?: string;
  image?: string;
  name?: string;
  title?: string;
  events?: number;
  locationId?: number;
  addedToFavorite?: boolean;
  favoriteData?: FSChips;

  constructor(model: VenueCard) {
    this.id = model.id || 0;
    this.label = model.label || '';
    this.image = model.image || '';
    this.name = model.name || '';
    this.title = model.title || '';
    this.events = model.events || 0;
    this.locationId = model.locationId || 0;
    this.addedToFavorite = model.addedToFavorite || false;
    this.favoriteData = model.favoriteData || AddFavoriteVenue;
  }
}

export class FSTabBar {
  id?: number;
  name?: string;
  activate?: boolean;
  isDisable?: boolean;

  constructor(model: FSTabBar) {
    this.id = model.id || 0;
    this.activate = model.activate || false;
    this.name = model.name || '';
    this.isDisable = model.isDisable || false;
  }
}

export class PageOptions {
  pageNumber?: number;
  pageSize?: number;

  constructor(model: PageOptions) {
    this.pageNumber = model.pageNumber || 0;
    this.pageSize = model.pageSize || 1000;
  }
}

export class ListResult<T> {
  data?: Array<T>;
  pageOptions?: PageOptions;

  constructor(model: ListResult<T>) {
    this.data = model.data || (null as any);
    this.pageOptions = model.pageOptions || (null as any);
  }
}

export class ArrayChanges<T> {
  created?: Array<T>;
  updated?: Array<T>;
  deleted?: Array<T>;
  olds?: Array<T>;

  constructor(model: ArrayChanges<T>) {
    this.created = model.created || new Array<T>();
    this.updated = model.updated || new Array<T>();
    this.deleted = model.deleted || new Array<T>();
    this.olds = model.olds || new Array<T>();
  }
}

export class LeaderboardWinnerPlayer {
  name?: string;
  pos?: number;
  score?: number;

  constructor(model: LeaderboardWinnerPlayer) {
    this.name = model.name || '';
    this.pos = model.pos || 0;
    this.score = model.score || 0;
  }
}
