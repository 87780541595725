import { HttpParams } from '@angular/common/http';
import {
  SortOption,
  PriorityArrayItem,
  SortDirection,
  VerticalDirection,
} from './modules/fs-controls-shared.model';
import { AbstractControl, FormControl } from '@angular/forms';
import { InvalidCharacters } from './static-objects';

export const $ = (selector: string) => {
  return document.querySelectorAll(selector);
};

export const classGroupReplacement = (
  el: HTMLElement,
  oldTokens: string[],
  newToken: string
) => {
  if (el?.classList.length && oldTokens?.length)
    oldTokens.forEach((token) => {
      if (el.classList.contains(token)) {
        newToken?.length
          ? el.classList.replace(token, newToken)
          : el.classList.remove(token);
      }
    });
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const intialValueInterval = async (
  callbackValue: () => {},
  delay: number = 100
): Promise<any> => {
  while (typeof callbackValue() === 'undefined' || callbackValue() === null)
    await sleep(delay);

  return callbackValue();
};

export const lowerCase = new RegExp(/[a-z]/);
export const upperCase = new RegExp(/[A-Z]/);
export const hasNumber = new RegExp(/[0-9]/);
export const hasSpecialCharacter = new RegExp(
  /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/
);
export const clearWhiteSpaces = new RegExp(/^\s+|\s+$|\s+(?=\s)/);
export const moreThan2WP = new RegExp(/\s*\s$|\s{2,}/);
export const startEndWP = new RegExp(/\s{2,}/g);
export const anyWP = new RegExp(/\s/);
export const guidPattern = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);
export const phoneNumberPattern = new RegExp(/^[1-9]\d{2}-\d{3}-\d{4}$/);

export const passwordValidation = (value: string): boolean =>
  value?.length &&
  new RegExp(lowerCase.source).test(value) &&
  new RegExp(upperCase.source).test(value) &&
  new RegExp(hasNumber.source).test(value) &&
  new RegExp(hasSpecialCharacter.source).test(value) &&
  value.length > 7 &&
  value.length < 50;

export const buildQueryParams = (obj: Object): HttpParams => {
  let queryParams = new HttpParams();
  for (const key in obj) queryParams = queryParams.append(key, obj[key]);
  return queryParams;
};

export const getUrlParamsAsString = (): string => {
  const query = window.location.href?.split('?');
  if (query?.length > 1) return query[1];
  return '';
};

export const replaceBaseUrl = (
  targetUrl: string,
  includePath: boolean = true,
  includeQuery: boolean = true,
): string => {
  if (!targetUrl?.length) return targetUrl;

  let url = `${targetUrl}`;

  if (includePath) url += `${window.location.pathname}`;

  if (includeQuery) url += `${window.location.search}`;

  return url;
};

export const dateToMedium = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const dateToShort = (date: Date): string => {
  return new Date(date).toLocaleString('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });
};

export const convertUrlParamsToObject = (url: string): any => {
  if (url?.length) {
    url = url.replace('%20', '+');

    let segments = url.split('&');
    let result = {};

    segments.forEach((segment) => {
      let field = segment.split('=');
      result[field[0]] = field[1];
    });

    return result;
  }

  return null;
};

export const dateJSToInputDate = (date: Date | any): string => {
  if (!date) return '';

  if (typeof date === 'string') date = new Date(date);

  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);

  return `${date.getFullYear()}-${month}-${day}`;
};

export const inputDateToDateJS = (date: string): Date => {
  let resultDate = new Date();

  if (date?.length) {
    let arr = date.split('-').map((m) => +m);

    resultDate.setFullYear(arr[0]);
    resultDate.setMonth(arr[1] - 1);
    resultDate.setDate(arr[2]);

    return resultDate;
  }

  return resultDate;
};

export const subStringFromEnd = (source: string, length: number) => {
  if (source?.length)
    return source.substring(source.length - length, source.length);
  else return '';
};

export const getUpperFirstChars = (
  source: string,
  seperator: string = ' '
): string => {
  try {
    if (source?.length) {
      let textArray = source.split(seperator);

      if (textArray?.length)
        return textArray.map((m) => m[0].toUpperCase()).join('');

      return source[0].toUpperCase();
    }

    return '';
  } catch (error) {
    return '';
  }
};

export const distinctArray = <T>(source: T[]): T[] => {
  return source?.filter((item, index) => source.indexOf(item) === index);
};

export const distinctObjArray = <T>(source: T[], key: string = 'id'): T[] => {
  let objectsArr = new Array<T>();
  let keysArr = distinctArray<any>(source.map((m) => m[key]));

  if (keysArr?.length) {
    for (let i = 0; i < keysArr.length; i++) {
      objectsArr.push(source.find((f) => f[key] === keysArr[i]));
    }
  }

  return objectsArr;
};

export const distinctObjArrayAsync = <T>(
  source: T[],
  key: string = 'id'
): Promise<T[]> => {
  return new Promise(async (resolve) => {
    let result: T[] = null;

    while (!result) {
      result = distinctObjArray(source, key);
      await sleep(1);
    }

    return resolve(result);
  });
};

export const sumNumbers = (
  source: number[],
  from: number = 0,
  to: number = source.length
): number => {
  let sum: number = 0;
  for (let i = from; i < to; i++) sum += source[i];
  return sum;
};

export const removeStorageItemsByExclude = (excludeKeys?: string[]): void => {
  let index = 0;

  while (true) {
    let key = window.localStorage.key(index);

    if (key?.length) {
      if (excludeKeys?.length) {
        let keyIndex = excludeKeys.findIndex((f) => f === key);

        if (keyIndex !== -1) {
          excludeKeys.slice(keyIndex, 1);
          index++;
          continue;
        }
      }

      window.localStorage.removeItem(key);
      index++;
    } else return;
  }
};

export const convertToInt = (value: string): number => {
  try {
    if (value?.length) {
      value = value.trim().replace(' ', '');

      if (value?.length) {
        let num = parseInt(value);

        if (!Number.isNaN(num) && typeof num === 'number') {
          return num;
        }
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
};

export const convertToFlatArray = <T>(source: Array<T>): Array<T> => {
  return source.reduce((accumulator, value) => accumulator.concat(value), []);
};

export const clearStringFromWhiteSpace = (value: string): string => {
  try {
    if (value?.length) return value.trim().replace(clearWhiteSpaces, '');
    return '';
  } catch (error) {
    return '';
  }
};

export const hasMoreThan2WP = (value: string): boolean => {
  return moreThan2WP.test(value);
};

export const hasStartEndWP = (value: string): boolean => {
  return startEndWP.test(value);
};

export const hasAnyWP = (value: string): boolean => {
  return anyWP.test(value);
};

export const fullCheckNumber = (value: number): boolean => {
  try {
    if (
      value === 0 ||
      (value !== undefined && value !== null && !Number.isNaN(value))
    )
      return true;

    return false;
  } catch (error) {
    return false;
  }
};

export const getElementIndexFromParent = (el: HTMLElement): number => {
  try {
    let parent = el.parentElement;
    return Array.from(
      parent.querySelectorAll(el.tagName.toLowerCase())
    )?.findIndex((f) => f === el);
  } catch (error) {
    return -1;
  }
};

export const convertToConstObject = <T>(source: Object): T => {
  try {
    const obj = {};

    for (const key in source) {
      Object.defineProperty(obj, key, {
        value: source[key],
        writable: false,
        enumerable: false,
        configurable: false,
      });
    }

    return obj as T;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const breakObjectReference = <T>(source: Object): T => {
  return source ? (JSON.parse(JSON.stringify(source)) as T) : null;
};

export const getUrlFragment = (): string => {
  let hash = window.location.hash;

  if (hash?.length) return window.location.hash?.substring(1, hash.length);
  return '';
};

export const arrayPrioritization = <ArrayType, ValueType>(
  source: Array<ArrayType>,
  options: PriorityArrayItem<ValueType>[],
  middleSorted: SortOption = null
): Array<ArrayType> => {
  try {
    if (!source?.length || !options?.length) return source;

    let topItems = new Array<ArrayType>(),
      bottomItems = new Array<ArrayType>(),
      middleItems = new Array<ArrayType>();

    for (let i = 0; i < source.length; i++) {
      let option = options.find((f) => f.value === source[i][f.fieldName]);

      if (option) {
        if (option.direction === VerticalDirection.Top) {
          topItems.push(source[i]);
        } else {
          bottomItems.push(source[i]);
        }
      } else {
        middleItems.push(source[i]);
      }
    }

    if (middleSorted) {
      if (middleSorted.direction === SortDirection.DESC) {
        middleItems = middleItems.sort((a, b) => {
          if (a[middleSorted.field] > b[middleSorted.field]) return 1;
          else if (a[middleSorted.field] < b[middleSorted.field]) return -1;
          else return 0;
        });
      } else if (middleSorted.direction === SortDirection.ASC) {
        middleItems = middleItems.sort((a, b) => {
          if (a[middleSorted.field] < b[middleSorted.field]) return 1;
          else if (a[middleSorted.field] > b[middleSorted.field]) return -1;
          else return 0;
        });
      }
    }

    return topItems.concat(middleItems).concat(bottomItems);
  } catch (error) {
    console.error(error);
    return source;
  }
};

export const convertDateUTCToLocal = (date: Date | string): Date => {
  if (typeof date === 'string') date = new Date(date);

  let offset = date.getTimezoneOffset();
  let calced = date.getTime() - offset * 60000;
  let newDate = new Date(calced);

  return newDate;

  //return new Date(date);
};

export const downloadFile = (name: string, url: string): void => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  a.click();
};

const base64 = (value: string) => {
  return window.btoa(unescape(encodeURIComponent(value)));
};

const excelFormat = (s: string, c: string) => {
  return s.replace(/{(\w+)}/g, (m, p) => {
    return c[p];
  });
};

export const convertTableToExcel3 = (
  tables: Array<HTMLTableElement>,
  wsnames: Array<string>,
  wbname: string,
  appname: string
) => {
  const uri = 'data:application/vnd.ms-excel;base64,',
    tmplWorkbookXML = `<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>
    <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">
      <DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
        <Author>Axel Richter</Author>
        <Created>{created}</Created>
      </DocumentProperties>
      <Styles>
        <Style ss:ID="Currency">
          <NumberFormat ss:Format="Currency"></NumberFormat>
        </Style>
        <Style ss:ID="Date">
          <NumberFormat ss:Format="Medium Date"></NumberFormat>
        </Style>
      </Styles>
      {worksheets}
    </Workbook>`,
    tmplWorksheetXML = `
    <Worksheet ss:Name="{nameWS}">
      <Table>{rows}</Table>
    </Worksheet>`,
    tmplCellXML = `
    <Cell{attributeStyleID}{attributeFormula}>
      <Data ss:Type="{nameType}">{data}</Data>
    </Cell>`;

  let ctx: any;
  let workbookXML: any;
  let worksheetsXML: string = '';
  let rowsXML: string = '';

  for (let i = 0; i < tables.length; i++) {
    // if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
    for (let j = 0; j < tables[i].rows.length; j++) {
      rowsXML += '<Row>';
      for (let k = 0; k < tables[i].rows[j].cells.length; k++) {
        let dataType = tables[i].rows[j].cells[k].getAttribute('data-type');
        let dataStyle = tables[i].rows[j].cells[k].getAttribute('data-style');
        let dataValue = tables[i].rows[j].cells[k].getAttribute('data-value');
        dataValue = dataValue
          ? dataValue
          : tables[i].rows[j].cells[k].innerHTML;
        let dataFormula =
          tables[i].rows[j].cells[k].getAttribute('data-formula');
        dataFormula = dataFormula
          ? dataFormula
          : appname == 'Calc' && dataType == 'DateTime'
          ? dataValue
          : null;
        ctx = {
          attributeStyleID:
            dataStyle == 'Currency' || dataStyle == 'Date'
              ? ' ss:StyleID="' + dataStyle + '"'
              : '',
          nameType:
            dataType == 'Number' ||
            dataType == 'DateTime' ||
            dataType == 'Boolean' ||
            dataType == 'Error'
              ? dataType
              : 'String',
          data: dataFormula ? '' : dataValue,
          attributeFormula: dataFormula
            ? ' ss:Formula="' + dataFormula + '"'
            : '',
        };
        rowsXML += excelFormat(tmplCellXML, ctx);
      }
      rowsXML += '</Row>';
    }
    ctx = { rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i };
    worksheetsXML += excelFormat(tmplWorksheetXML, ctx);
    rowsXML = '';
  }

  ctx = { created: new Date().getTime(), worksheets: worksheetsXML };
  workbookXML = excelFormat(tmplWorkbookXML, ctx);
  console.log(workbookXML);
  const link = document.createElement('a');
  link.href = uri + base64(workbookXML);
  link.download = wbname || 'Workbook.xls';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertTableToExcel = (name: string, table: HTMLElement): void => {
  const uri = 'data:application/vnd.ms-excel;base64,',
    template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>${table.outerHTML}</body></html>`;
  const ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
  const encoding = `${uri}${window.btoa(
    template.replace(/{(\w+)}/g, (m, p) => ctx[p])
  )}`;

  downloadFile(`${name}.xls`, encoding);
};

export const convertTableToExcel2 = (
  name: string,
  table: HTMLElement
): void => {
  let tableData = table.outerHTML;
  tableData = tableData.replace(/<A[^>]*>|<\/A>/g, '');
  tableData = tableData.replace(/<input[^>]*>|<\/input>/gi, '');

  downloadFile(
    `${name}.xls`,
    `data:application/vnd.ms-excel, ${encodeURIComponent(tableData)}`
  );
};

export const signNumber = (value: number): string => {
  if (value > 0) return `+${value}`;
  else if (value < 0) return value.toString();
  else return value.toString();
};

export const isValidGUID = (value: string): boolean => guidPattern.test(value);

export const manualCount = (
  dir: 'up' | 'down',
  value: number,
  min: number = 0,
  max: number = 100
): number => {
  switch (dir) {
    case 'up':
      if (value < max) return ++value;
      return value;
    case 'down':
      if (value > min) return --value;
      return value;
    default:
      return value;
  }
};

export const invalidCharReplacer = (
  source: string,
  replaceWith: string = ''
): string => {
  if (!source?.length) return source;

  InvalidCharacters?.forEach((invalidChar) => {
    source = source.replace(new RegExp(`\\${invalidChar}`, 'g'), replaceWith);
  });

  return source;
};

export const stringCutter = (source: string, maxLength: number): string => {
  if (!source?.length || source.length < maxLength) return source;
  return source.substring(0, maxLength).concat('...');
};

export class CustomValidator {
  private static _isEmptyValue(control: AbstractControl): boolean {
    return !control.value || !control.value.length;
  }

  static MoreThan2WhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = hasMoreThan2WP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static StartEndWhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = hasStartEndWP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static AnyWhiteSpace(control: AbstractControl): any {
    if (CustomValidator._isEmptyValue(control)) return null;

    let hasErrors = hasAnyWP(control.value);

    if (hasErrors) return { onlyWhitespacesValidator: true };

    return null;
  }

  static phoneValidation(
    event: KeyboardEvent,
    phoneControl: FormControl<string>
  ): void {
    let el = <HTMLInputElement>event.target;

    if (
      el?.value.length &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      if (el.value.length === 3 || el.value.length === 7) {
        phoneControl.setValue(el.value + '-');
      }
    }
  }

  static LineChecker(charPerLine: number, maxEnters: number = 0): any {
    return (control: AbstractControl): any => {
      if (!control.value || !control.value.length) return null;

      let lines: Array<string> = control.value.split('\n');

      if (maxEnters > 0 && lines.length > maxEnters) {
        return { overMaxChar: true };
      }

      if (lines?.length) {
        for (let i = 0; i < lines.length; i++)
          if (lines[i].length > charPerLine) return { overMaxChar: true };
      }

      return null;
    };
  }
}

export class RandomGenerator {
  public static boolean(): boolean {
    return Math.round(Math.random() * 10) % 2 === 0;
  }

  public static number(
    digits: number = 100,
    inNegRange: boolean = false
  ): number {
    const num = Math.round(Math.random() * digits);

    if (num === 0) return num;

    if (inNegRange) {
      return RandomGenerator.boolean() ? num : num * -1;
    }

    return num;
  }

  public static betweenTwoNumber(min: number = 0, max: number = 100): number {
    const minCeiled = Math.ceil(min),
      maxFloored = Math.floor(max);

    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
  }

  public static inList<T>(list: Array<T>): T {
    if (!list?.length) return null;
    return list[RandomGenerator.betweenTwoNumber(0, list.length)];
  }

  public static divisibleNumber(
    target: number,
    min: number = 0,
    max: number = 100
  ): number {
    if (target < min || target > max) return 0;

    let value = RandomGenerator.betweenTwoNumber(min, max);

    if (value > target) {
      while (value % target !== 0) value--;
      return value;
    } else if (value < target) {
      while (value % target !== 0) value++;
      return value;
    } else {
      return value;
    }
  }

  public static stringId(length: number = 5): string {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      charactersLength = characters.length;
    let result = '',
      counter = 0;

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
  }
}

export class CustomArray {
  public static push<T>(array: Array<T>, item: T): Array<T> {
    if (typeof array === 'undefined' || !array) array = new Array<T>();
    if (item) array.push({ ...item });
    return array;
  }

  public static unshift<T>(array: Array<T>, item: T): Array<T> {
    if (typeof array === 'undefined' || !array) array = new Array<T>();
    if (item) array.unshift({ ...item });
    return array;
  }
}
